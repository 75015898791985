import qs from "qs";

const SIMTAG_PREFIX = "SIMTAG_";

/**
 * Get the library image id
 * @param previewUrl - The preview URL to look at
 * @returns - string containing the vistaprint library image id, undefined otherwise
 */
export function getLibraryImageId(previewUrl: string) {
    const queryString = new URL(previewUrl).search;
    return qs.parse(queryString).vpid as string | undefined;
}

/**
 * Get the deposit photos image id
 * @param originalUrl - The original URL to look at
 * @returns - string containing the deposit photos image id, undefined otherwise
 */
export function getDepositPhotosImageId(originalUrl: string | undefined) {
    if (!originalUrl?.includes("/image/")) {
        return undefined;
    }
    try {
        const pathPieces = new URL(originalUrl).pathname.split("/");
        return pathPieces[pathPieces.length - 1]; // last piece of path is id
    } catch {
        // Should never happen, but if it does don't bomb out
        return undefined;
    }
}

export function getClipartImageId(requestId: string) {
    if (!requestId?.startsWith(SIMTAG_PREFIX)) return undefined;

    return requestId.replace(SIMTAG_PREFIX, "");
}
