import React from "react";

export function EllipseIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <ellipse cx="13" cy="13" rx="7" ry="4" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
}
EllipseIcon.displayName = "EllipseIcon";
