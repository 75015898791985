import { useState, useEffect } from "react";
import { useDesigner, useActiveCanvas, useSelection } from "@designer-suite";
import { getItemsSortedByZIndex } from "./utils";

/**
 * Returns an array of document items from the active canvas ordered by zIndex.
 * Highest zOrder number will be at top, lowest (usually the background) will be at bottom of list.
 */
export function useZOrderedActiveCanvasItems() {
    const designer = useDesigner();
    const activeCanvas = useActiveCanvas();
    // Need to make sure updates occur on changes in selection - especially deselect all
    const selection = useSelection("change:selected select model:change:zindex");
    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        if (!designer) return;

        const updateItems = () => {
            if (activeCanvas) {
                const orderedItems = getItemsSortedByZIndex(activeCanvas.items as Item[]);
                setItems(orderedItems);
            }
        };

        updateItems();

        const unsubscribeAdd = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_ADDED, updateItems);
        const unsubscribeDelete = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_DELETED,
            updateItems
        );
        const unsubscribeChanged = designer.api.events.subscribe(
            designer.api.events.eventTypes.ITEMS_CHANGED,
            updateItems
        );
        /* eslint-disable consistent-return */
        return () => {
            unsubscribeAdd();
            unsubscribeDelete();
            unsubscribeChanged();
        };
    }, [activeCanvas, designer, selection]);

    return { items };
}
