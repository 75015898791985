import { EditTableIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import { ItemTypeIcon } from "./ItemTypeIcon";
import { messages } from "./messages";

export const TableTypeIcon = () => {
    const { t } = useTranslationSSR();

    return (
        <ItemTypeIcon title={t(messages.typeTable.id)}>
            <EditTableIcon />
        </ItemTypeIcon>
    );
};

TableTypeIcon.displayName = "TableTypeIcon";
