import { DepositPhotosIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ItemTypeIcon } from "./ItemTypeIcon";
import { messages } from "./messages";

interface DepositPhotosTypeIconProps {
    copyableText: string;
}

export const DepositPhotosTypeIcon = ({ copyableText }: DepositPhotosTypeIconProps) => {
    const { t } = useTranslationSSR();

    return (
        <CopyToClipboard text={copyableText}>
            <ItemTypeIcon title={t(messages.typeDPLibraryImage.id, { imageId: copyableText })}>
                <DepositPhotosIcon />
            </ItemTypeIcon>
        </CopyToClipboard>
    );
};

DepositPhotosTypeIcon.displayName = "DepositPhotosTypeIcon";
