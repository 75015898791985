import {
    ClipartImageTypeIcon,
    EllipseShapeTypeIcon,
    ImageTypeIcon,
    LineShapeTypeIcon,
    QrCodeTypeIcon,
    RectangleShapeTypeIcon,
    TableTypeIcon,
    TextTypeIcon,
    UploadedImageTypeIcon,
    getDepositPhotosImageId,
    getLibraryImageId,
    OtherTypeIcon,
    DepositPhotosTypeIcon,
    NounTypeIcon,
    getClipartImageId
} from "@shared/features/AdvancedLayerTool";
import { messages } from "@shared/features/AdvancedLayerTool/ItemTypeIcons";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { isTable, isWordArt } from "@utilities";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import { isQRCode } from "../QRCodes/QRCodeUtils";
import { getOriginalUrl, getPreviewUrl, hasPreviewUrl } from "./utils";

interface ItemTypeIconProps extends React.HTMLAttributes<HTMLDivElement> {
    item: Item;
}

export function ItemTypeIcon({ item }: ItemTypeIconProps) {
    const { t } = useTranslationSSR();

    if (item.itemType === ItemTypes.SHAPE) {
        const shapeType = item._itemViewModel.model.get("module");

        switch (shapeType) {
            case "Rectangle":
                return <RectangleShapeTypeIcon />;
            case "Ellipse":
                return <EllipseShapeTypeIcon />;
            case "Line":
                return <LineShapeTypeIcon />;
            default:
                return <OtherTypeIcon title={t(messages.typeUnknownShape.id, { shapeType })} />;
        }
    }

    if (item.itemType === ItemTypes.IMAGE) {
        // Placeholders will be reported as images currently
        if (hasPreviewUrl(item)) {
            const previewUrl = getPreviewUrl(item);

            // classic image / old world image id
            const vpid = getLibraryImageId(previewUrl);

            // template / old world image
            if (vpid) {
                // For some reason the CopyToClipboard seems to need a div here to properly capture the click event
                return <ImageTypeIcon copyableText={vpid} />;
            }

            // Deposit photo image
            const originalUrl = getOriginalUrl(item);
            const imageId = getDepositPhotosImageId(originalUrl);

            if (imageId) {
                return <DepositPhotosTypeIcon copyableText={imageId} />;
            }

            // Noun project image
            const metaData = item._itemViewModel.model.get("studioMetadata");

            if (metaData?.thirdPartyUploadInfo?.source === "NounProject" || originalUrl?.includes("icon")) {
                const iconId = metaData.thirdPartyUploadInfo?.sourceId.split("_")[0];

                return <NounTypeIcon copyableText={iconId} />;
            }

            // Clipart image
            const requestId = item._itemViewModel.model.get("requestId");
            const clipartImageId = getClipartImageId(requestId);

            if (clipartImageId) {
                return <ClipartImageTypeIcon copyableText={clipartImageId} />;
            }
        }

        return <UploadedImageTypeIcon />;
    }

    if (item.itemType === ItemTypes.TEXT) {
        return <TextTypeIcon title={t(messages.typeText.id)} />;
    }

    if (isWordArt(item)) {
        return <TextTypeIcon title={t(messages.typeWordArt.id)} />;
    }

    if (isTable(item)) {
        return <TableTypeIcon />;
    }

    if (isQRCode(item)) {
        return <QrCodeTypeIcon copyableText={item.data.url} />;
    }

    if (item.itemType === ItemTypes.ITEM_REFERENCE) {
        return (
            <OtherTypeIcon title={t(messages.typeUnknownItemReference.id, { type: (item as ReferenceItem).type })} />
        );
    }

    // This shouldn't happen - but if design-stack adds a core type in the future it will fall through to here.
    return <OtherTypeIcon title={t(messages.typeUnknown.id)} />;
}

ItemTypeIcon.displayName = "ItemTypeIcon";
