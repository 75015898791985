import { TextIcon } from "@shared/features/StudioChrome";
import React from "react";
import { ItemTypeIcon } from "./ItemTypeIcon";

interface TextTypeIconProps {
    title: string;
}

export const TextTypeIcon = ({ title }: TextTypeIconProps) => {
    return (
        <ItemTypeIcon title={title}>
            <TextIcon />
        </ItemTypeIcon>
    );
};

TextTypeIcon.displayName = "TextTypeIcon";
