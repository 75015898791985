import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { LockedIcon, UnlockedIcon } from "../StudioChrome";
import * as styles from "./ToggleLockButton.module.scss";
import { BaseButton } from "./BaseButton";

const messages = defineMessages({
    lockedTitle: {
        id: "easel.ui.layers.row.lockedTitle",
        defaultMessage: "Locked. Click to unlock.",
        description: {
            note: "Gives lock status locked and instructions on how to unlock."
        }
    },
    unlockedTitle: {
        id: "easel.ui.layers.row.unlockedTitle",
        defaultMessage: "Unlocked. Click to lock.",
        description: {
            note: "Gives lock status unlocked and instructions on how to lock."
        }
    }
});

interface ToggleLockButtonProps {
    onLockToggle: () => void;
    isLocked: boolean;
}

export const ToggleLockButton = ({ onLockToggle, isLocked }: ToggleLockButtonProps) => {
    const { t } = useTranslationSSR();

    const lockedTitle = t(messages.lockedTitle.id);
    const unlockedTitle = t(messages.unlockedTitle.id);
    const lockToggleTitle = isLocked ? lockedTitle : unlockedTitle;

    return (
        <BaseButton onClick={onLockToggle} className={styles.toggleLockButton} title={lockToggleTitle}>
            {isLocked ? <LockedIcon aria-hidden={true} /> : <UnlockedIcon aria-hidden={true} />}
        </BaseButton>
    );
};

ToggleLockButton.displayName = "ToggleLockButton";
