import React from "react";
import * as styles from "./ItemTypeIcon.module.scss";

interface ItemTypeIconProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
}

export function ItemTypeIcon({ title, ...rest }: ItemTypeIconProps) {
    return <div title={title} aria-label={title} className={styles.itemTypeIcon} {...rest} />;
}

ItemTypeIcon.displayName = "ItemTypeIcon";
