import { OtherIcon } from "@shared/features/StudioChrome";
import React from "react";
import { ItemTypeIcon } from "./ItemTypeIcon";

interface OtherTypeIconProps {
    title: string;
}

export const OtherTypeIcon = ({ title }: OtherTypeIconProps) => {
    return (
        <ItemTypeIcon title={title}>
            <OtherIcon />
        </ItemTypeIcon>
    );
};

OtherTypeIcon.displayName = "OtherTypeIcon";
