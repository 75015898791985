import { ClipartIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ItemTypeIcon } from "./ItemTypeIcon";
import { messages } from "./messages";

interface ClipartImageTypeIconProps {
    copyableText: string;
}

export const ClipartImageTypeIcon = ({ copyableText }: ClipartImageTypeIconProps) => {
    const { t } = useTranslationSSR();

    return (
        <CopyToClipboard text={copyableText}>
            <ItemTypeIcon title={t(messages.typeClipartLibraryImage.id, { imageId: copyableText })}>
                <ClipartIcon />
            </ItemTypeIcon>
        </CopyToClipboard>
    );
};

ClipartImageTypeIcon.displayName = "ClipartImageTypeIcon";
