import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DownloadIcon } from "../StudioChrome";
import { BaseButton } from "./BaseButton";
import * as styles from "./DownloadButton.module.scss";

const messages = defineMessages({
    downloadButton: {
        id: "easel.ui.layers.row.downloadButton",
        defaultMessage: "Download",
        description: {
            note: "Button to download the print url of current image"
        }
    }
});

interface DownloadButtonProps {
    onClick: () => void;
}

export const DownloadButton = ({ onClick }: DownloadButtonProps) => {
    const { t } = useTranslationSSR();

    return (
        <BaseButton onClick={onClick} className={styles.downloadButton} title={t(messages.downloadButton.id)}>
            <DownloadIcon aria-hidden={true} />
        </BaseButton>
    );
};

DownloadButton.displayName = "DownloadButton";
