import { EllipseIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import { ItemTypeIcon } from "./ItemTypeIcon";
import { messages } from "./messages";

export const EllipseShapeTypeIcon = () => {
    const { t } = useTranslationSSR();

    return (
        <ItemTypeIcon title={t(messages.typeEllipse.id)}>
            <EllipseIcon />
        </ItemTypeIcon>
    );
};

EllipseShapeTypeIcon.displayName = "EllipseShapeTypeIcon";
