import React, { PropsWithChildren } from "react";
import { Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import * as styles from "./LayerRow.module.scss";

interface LayerRowProps extends PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
    id: string;
    index: number;
    isSelected: boolean;
    onHoverOver: (id: string) => void;
    onHoverEnd: () => void;
}

export function LayerRow({ onHoverOver, onHoverEnd, index, id, isSelected, children }: LayerRowProps) {
    return (
        <Draggable draggableId={id} index={index}>
            {provided => (
                <div
                    role="button"
                    tabIndex={0}
                    className={classNames(styles.layerRow, { [styles.layerRowSelected]: isSelected })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseEnter={() => onHoverOver(id)}
                    onMouseLeave={onHoverEnd}
                >
                    {children}
                </div>
            )}
        </Draggable>
    );
}
LayerRow.displayName = "LayerRow";
