import { isItemReference } from "@utilities";
import { ItemReferenceTypes } from "@shared/utils/CimDoc";
import type { ItemSelection } from "../@types/designer";

export function isQRCode(item: CanvasItem): item is QRCodeItem {
    return isItemReference(item) && item.type === ItemReferenceTypes.QR_CODE;
}

export function qrCodeFilter(selection: ItemSelection) {
    return selection.reduce(
        (accumulator: any, current: CanvasItem) => {
            if (isQRCode(current)) {
                accumulator.qrcodes.push(current);
            }
            return accumulator;
        },
        { qrcodes: [] }
    );
}
