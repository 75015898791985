import { ImageIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ItemTypeIcon } from "./ItemTypeIcon";
import { messages } from "./messages";

interface ImageTypeIconProps {
    copyableText: string;
}

export const ImageTypeIcon = ({ copyableText }: ImageTypeIconProps) => {
    const { t } = useTranslationSSR();

    return (
        <CopyToClipboard text={copyableText}>
            <ItemTypeIcon title={t(messages.typeLibraryImage.id, { vpid: copyableText })}>
                <ImageIcon />
            </ItemTypeIcon>
        </CopyToClipboard>
    );
};

ImageTypeIcon.displayName = "ImageTypeIcon";
