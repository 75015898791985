import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import * as styles from "./BaseButton.module.scss";

interface BaseButtonProps extends PropsWithChildren<{}> {
    onClick: () => void;
    className?: string;
    title?: string;
}

export const BaseButton = ({ onClick, className, title, children }: BaseButtonProps) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames(styles.layerRowButton, className)}
            title={title}
            aria-label={title}
        >
            {children}
        </button>
    );
};

BaseButton.displayName = "BaseButton";
