import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DeleteIcon } from "../StudioChrome";
import * as styles from "./DeleteButton.module.scss";
import { BaseButton } from "./BaseButton";

const messages = defineMessages({
    deleteButton: {
        id: "easel.ui.layers.row.deleteButton",
        defaultMessage: "Delete",
        description: {
            note: "Button to delete the current item"
        }
    }
});

interface DeleteButtonProps {
    onClick: () => void;
}

export const DeleteButton = ({ onClick }: DeleteButtonProps) => {
    const { t } = useTranslationSSR();

    return (
        <BaseButton onClick={onClick} className={styles.deleteButton} title={t(messages.deleteButton.id)}>
            <DeleteIcon aria-hidden={true} />
        </BaseButton>
    );
};

DeleteButton.displayName = "DeleteButton";
