import { useDesigner } from "@designer-suite";
import saveAs from "file-saver";
import { bringForward, getPrintUrl, hasPrintUrl, sendBackward } from "./utils";
import { useZOrderedActiveCanvasItems } from "./useZOrderedActiveCanvasItems";

export const useLayerEvents = () => {
    const { items } = useZOrderedActiveCanvasItems();
    const designer = useDesigner();

    function onDragEnd(result: any) {
        // Not dropped where the destination was knowable
        if (!result.destination) {
            return;
        }

        // Order didn't change when dropped, no action needed
        if (result.destination.index === result.source.index) {
            return;
        }

        // Item was dragged higher in the list - bring forward in the zOrder
        if (result.destination.index < result.source.index) {
            const indexDiff = result.source.index - result.destination.index;
            for (let i = 0; i < indexDiff; i += 1) {
                bringForward(items, items[result.source.index]);
            }
            // An item must get selected to trigger refreshing the list of items with its new order
            //  No event trigger is available that triggers on changing the zIndex of an item if
            //  its not selected
            designer?.selectionManager.select([items[result.source.index]._itemViewModel]);
            return;
        }

        // Item was dragged lower in the list - send backward in the zOrder
        if (result.destination.index > result.source.index) {
            const indexDiff = result.destination.index - result.source.index;
            for (let i = 0; i < indexDiff; i += 1) {
                sendBackward(items, items[result.source.index]);
            }
            designer?.selectionManager.select([items[result.source.index]._itemViewModel]);
        }
    }

    // When the delete icon is clicked, delete this item
    const onDelete = (item: Item) => {
        if (!designer) return;

        designer.api.design.updateItem(item.id, mutableItem => {
            mutableItem.remove();
        });
    };

    // When the image download button is clicked, download print url of image
    const onDownload = async (item: Item) => {
        if (!designer || !hasPrintUrl(item)) return;

        saveAs(getPrintUrl(item), item._itemViewModel.model.get("id"));
    };

    // Handler to toggle the lock status for the item when the lock/unlock icon is clicked.
    const onLockToggle = (item: Item) => {
        if (!designer) return;

        const isLocked = item._itemViewModel.model.get("locked");

        item._itemViewModel.model.set("locked", !isLocked);
        // Always remove "restricted" which prevents editing
        item._itemViewModel.model.set("restricted", false);
        designer.selectionManager.select([item._itemViewModel]);
    };

    // Handle clicking on the row - currently selects the item on the canvas
    const onLabelClick = (item: Item) => {
        if (!designer) return;

        designer.selectionManager.select([item._itemViewModel]);
    };

    // handle hovering over the layer row
    const onHoverOver = (item: Item) => {
        // This property is used by designer to control the regular item hover over
        //  Will show the same hover box that you would get hovering over the item
        //   on the canvas
        item._itemViewModel.set("over", true);
    };

    // handle the hover stopping
    const onHoverEnd = (item: Item) => {
        item._itemViewModel.unset("over");
    };

    return {
        onDelete,
        onDragEnd,
        onDownload,
        onLockToggle,
        onLabelClick,
        onHoverOver,
        onHoverEnd
    };
};
