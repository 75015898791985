/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
    LayersPanel,
    LayerRow,
    ToggleLockButton,
    DeleteButton,
    DownloadButton
} from "@shared/features/AdvancedLayerTool";
import { layersPanelMessages } from "@shared/features/AdvancedLayerTool/LayersPanel";
import { Typography } from "@vp/swan";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useLayerEvents } from "./useLayerEvents";
import { ItemTypeIcon } from "./ItemTypeIcon";
import * as styles from "./LayerContentPanel.module.scss";
import { useZOrderedActiveCanvasItems } from "./useZOrderedActiveCanvasItems";
import { hasPrintUrl } from "./utils";
import { ItemContentInformation } from "./ItemContentInformation";

export const LayerContentPanel = () => {
    const { onDelete, onDragEnd, onHoverEnd, onHoverOver, onLockToggle, onDownload, onLabelClick } = useLayerEvents();
    const { t } = useTranslationSSR();
    const { items } = useZOrderedActiveCanvasItems();

    return (
        <>
            {items?.length === 0 && (
                <Typography fontSize="1" textAlign="left" fontWeight="normal" textColor="dark-grey">
                    {t(layersPanelMessages.empty.id)}
                </Typography>
            )}
            <LayersPanel onDragEnd={onDragEnd}>
                {items.map((item, index) => {
                    const isSelected = item._itemViewModel.get("selected") || item._itemViewModel.get("multiselected");
                    const isLocked = item._itemViewModel.model.get("locked");

                    return (
                        <LayerRow
                            key={item.id}
                            id={item.id}
                            onHoverOver={() => onHoverOver(item)}
                            onHoverEnd={() => onHoverEnd(item)}
                            index={index}
                            isSelected={isSelected}
                        >
                            <>
                                <ToggleLockButton onLockToggle={() => onLockToggle(item)} isLocked={isLocked} />
                                <div className={styles.itemTypeContainer}>
                                    <div className={styles.itemTypeIconContainer}>
                                        <ItemTypeIcon item={item} />
                                    </div>
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        className={styles.itemContentButton}
                                        onClick={() => onLabelClick(item)}
                                    >
                                        <ItemContentInformation item={item} />
                                    </div>
                                </div>

                                {hasPrintUrl(item) && (
                                    <DownloadButton aria-hidden={true} onClick={() => onDownload(item)} />
                                )}
                                <DeleteButton aria-hidden={true} onClick={() => onDelete(item)} />
                            </>
                        </LayerRow>
                    );
                })}
            </LayersPanel>
        </>
    );
};

LayerContentPanel.displayName = "LayerContentPanel";
