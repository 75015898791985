import React from "react";

interface Props extends React.HTMLAttributes<SVGSVGElement> {}

export function LockedIcon(props: Props) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} />
            <rect width={10} height={8} rx={1} transform="matrix(-1 0 0 1 13 6)" stroke="black" strokeWidth={1.5} />
            <path d="M5 6V5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5V6" stroke="black" strokeWidth={1.5} />
            <rect x={7} y={8} width={2} height={2} rx={1} fill="black" />
            <path d="M8 9L8 11" stroke="black" strokeLinecap="round" />
        </svg>
    );
}
LockedIcon.displayName = "LockedIcon";
