import { LineIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import { ItemTypeIcon } from "./ItemTypeIcon";
import { messages } from "./messages";

export const LineShapeTypeIcon = () => {
    const { t } = useTranslationSSR();

    return (
        <ItemTypeIcon title={t(messages.typeLine.id)}>
            <LineIcon />
        </ItemTypeIcon>
    );
};

LineShapeTypeIcon.displayName = "LineShapeTypeIcon";
