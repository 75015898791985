import { defineMessages } from "@vp/i18n-helper";

export const messages = defineMessages({
    typeClipartLibraryImage: {
        id: "easel.ui.layers.itemType.clipartlibraryImage",
        defaultMessage: 'Clipart Image: Click to copy image id "[[imageId]]" to clipboard.',
        description: {
            note:
                // eslint-disable-next-line max-len
                "Hover over text for a Clipart Image item to describe its type, and inform the user that clicking the button will copy the image id to the clipboard"
        }
    },
    typeDPLibraryImage: {
        id: "easel.ui.layers.itemType.dplibraryImage",
        defaultMessage: 'Deposit Photos Image: Click to copy image id "[[imageId]]" to clipboard.',
        description: {
            note:
                // eslint-disable-next-line max-len
                "Hover over text for a Deposit Photos Library Image item to describe its type, and inform the user that clicking the button will copy the image id to the clipboard"
        }
    },
    typeEllipse: {
        id: "easel.ui.layers.itemType.ellipse",
        defaultMessage: "Ellipse",
        description: {
            note: "Hover over text for a Ellipse item to describe its type"
        }
    },
    typeLibraryImage: {
        id: "easel.ui.layers.itemType.lbraryImage",
        defaultMessage: 'Library Image: Click to copy library image id "[[vpid]]" to clipboard.',
        description: {
            note:
                // eslint-disable-next-line max-len
                "Hover over text for a Library Image item to describe its type, and inform the user that clicking the button will copy the image id to the clipboard"
        }
    },
    typeLine: {
        id: "easel.ui.layers.itemType.line",
        defaultMessage: "Line",
        description: {
            note: "Hover over text for a Line item to describe its type"
        }
    },
    typeNounLibraryImage: {
        id: "easel.ui.layers.itemType.nounlibraryImage",
        defaultMessage: 'Noun Project Image: Click to copy icond id "[[iconId]]" to clipboard',
        description: {
            note: "Hover over text for an Image item to describe its type"
        }
    },
    typeUnknownItemReference: {
        id: "easel.ui.layers.itemType.unknownItemReference",
        defaultMessage: "Item: [[type]]",
        description: {
            note: "Hover over text for an item reference item whose type is unexpected"
        }
    },
    typeUnknownShape: {
        id: "easel.ui.layers.itemType.unknownShape",
        defaultMessage: "Shape: [[shapeType]]",
        description: {
            note: "Hover over text for a shape item whose type is unexpected"
        }
    },
    typeUnknown: {
        id: "easel.ui.layers.itemType.unknown",
        defaultMessage: "Unknown",
        description: {
            note: "Hover over text for an item whose type is unexpected"
        }
    },
    typeOther: {
        id: "easel.ui.layers.itemType.other",
        defaultMessage: "Other",
        description: {
            note: "Hover over text for an item whose type is not known"
        }
    },
    typeQrCode: {
        id: "easel.ui.layers.itemType.qrCode",
        defaultMessage: "QR Code: Click to copy [[url]]",
        description: {
            note: "Hover over text for a QR Code item to describe its type"
        }
    },
    typeRectangle: {
        id: "easel.ui.layers.itemType.rectangle",
        defaultMessage: "Rectangle",
        description: {
            note: "Hover over text for a Rectangle item to describe its type"
        }
    },
    typeTable: {
        id: "easel.ui.layers.itemType.table",
        defaultMessage: "Table",
        description: {
            note: "Hover over text for a Table item to describe its type"
        }
    },
    typeText: {
        id: "easel.ui.layers.itemType.text",
        defaultMessage: "Text",
        description: {
            note: "Hover over text for a Text item to describe its type"
        }
    },
    typeWordArt: {
        id: "easel.ui.layers.itemType.wordArt",
        defaultMessage: "Word Art",
        description: {
            note: "Hover over text for a Word Art item to describe its type"
        }
    },
    typeImage: {
        id: "easel.ui.layers.itemType.image",
        defaultMessage: "Image",
        description: {
            note: "Hover over text for an Image item to describe its type"
        }
    }
});
