import React from "react";

export function LineIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <line x1="5" y1="12" x2="21" y2="12" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
}
LineIcon.displayName = "LineIcon";
