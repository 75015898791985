/* eslint-disable max-len */
import React from "react";

export function EditIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M4.48024 11.0652L4.35385 9.069L10.9228 2.50007L12.9189 4.49623L6.35001 11.0652L4.48024 11.0652Z"
                stroke="black"
                strokeWidth={1.5}
            />
            <line x1={8.53131} y1={4.46967} x2={10.6366} y2={6.57494} stroke="black" strokeWidth={1.5} />
            <line x1={1.75} y1={14.25} x2={14.25} y2={14.25} stroke="black" strokeWidth={1.5} strokeLinecap="round" />
        </svg>
    );
}
EditIcon.displayName = "EditIcon";
