import { EditIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR } from "@vp/i18n-helper";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ItemTypeIcon } from "./ItemTypeIcon";
import { messages } from "./messages";

interface QrCodeTypeIconProps {
    copyableText: string;
}

export const QrCodeTypeIcon = ({ copyableText }: QrCodeTypeIconProps) => {
    const { t } = useTranslationSSR();

    return (
        <CopyToClipboard text={copyableText}>
            <ItemTypeIcon title={t(messages.typeQrCode.id, { url: copyableText })}>
                <EditIcon aria-hidden={true} />
            </ItemTypeIcon>
        </CopyToClipboard>
    );
};

QrCodeTypeIcon.displayName = "QrCodeTypeIcon";
