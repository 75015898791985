/* eslint-disable max-len */
import React from "react";

export function ClipartIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 30 30" fill="none" {...props}>
            <rect
                x="1"
                y="1"
                width="28"
                height="28"
                fill="none"
                rx="3"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g transform="translate(6, 4)">
                <g transform="scale(0.7)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24.872 0h-7.96v8h7.96V0zm-8 20c0 2.246-1.802 4-3.941 4-2.14 0-3.94-1.754-3.94-4s1.8-4 3.94-4c2.14 0 3.94 1.754 3.94 4zm8 0c0 6.627-5.346 12-11.941 12C6.336 32 .991 26.627.991 20S6.335 8 12.93 8s11.94 5.373 11.94 12zm80.764-12.186h-3"
                        fill="currentColor"
                    />
                </g>
            </g>
        </svg>
    );
}
ClipartIcon.displayName = "ClipartIcon";
